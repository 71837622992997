@-webkit-keyframes _1VsJA {
  from {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  to {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes _1VsJA {
  from {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  to {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.__Q-W2 {
  width: 100%;
  border-radius: 0;
  bottom: 0;
  left: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  position: fixed;
  background-color: rgb(255, 255, 255);
  height: auto;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 24px, rgb(0 0 0 / 5%) 0px 2px 6px;
  -webkit-animation: _1VsJA 1s ease both;
          animation: _1VsJA 1s ease both;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.__Q-W2 > ._2mo3X {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  color: rgb(0, 0, 0);
  text-align: left;
}

.__Q-W2 > ._2mo3X > ._2KezC {
  font-size: 1em;
  font-weight: 400;
  margin: 0 0 10px;
}

.__Q-W2 > ._2mo3X > ._2beoD {
  font-size: 0.8em;
  line-height: 1.5em;
  margin: 0 0 10px;
}

.__Q-W2 > ._2mo3X > ._71S8l {
  font-size: 0.8em;
  color: rgb(192, 192, 192);
  text-decoration: none;
  transition: color 0.5s ease;
}

.__Q-W2 > ._2mo3X > ._71S8l:hover {
  color: rgb(35, 157, 228);
}

.__Q-W2 > ._3wBL6 {
  display: flex;
  justify-content: space-around;
}

.__Q-W2 > ._3wBL6 > ._2hTXI {
  border: none;
  margin: 0;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  padding: 10px;
  width: 100%;
  border-right: 1px solid rgb(238, 238, 238);
  border-top: 1px solid rgb(238, 238, 238);
  transition: background-color 0.5s ease;
  outline: none;
}

.__Q-W2 > ._3wBL6 > ._2hTXI:first-child {
  border-bottom-left-radius: 10px;
}

.__Q-W2 > ._3wBL6 > ._2hTXI:last-child {
  border-bottom-right-radius: 10px;
  border-right: none;
}

.__Q-W2 > ._3wBL6 > ._2hTXI:hover {
  background-color: rgb(238, 238, 238);
}

@media screen and (min-width: 490px) {
  .__Q-W2 {
    width: 450px;
    border-radius: 10px;
    bottom: 30px;
    left: 20px;
  }
}

@media (prefers-color-scheme: dark) {
  .__Q-W2 {
    background-color: rgb(41, 41, 41);
  }

  .__Q-W2 > ._2mo3X {
    color: rgb(255, 255, 255);
  }

  .__Q-W2 > ._3wBL6 > ._2hTXI {
    color: rgb(255, 255, 255);
    background-color: rgb(41, 41, 41);
    border-right: 1px solid rgb(51, 51, 51);
    border-top: 1px solid rgb(51, 51, 51);
  }

  .__Q-W2 > ._3wBL6 > ._2hTXI:hover {
    background-color: rgb(51, 51, 51);
  }
}

